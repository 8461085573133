<template>
  <h1>Umsetzungsbegleitung</h1>
  <p>
    Wir implementieren gemeinsam mit Ihnen die von uns für Sie entwickelte
    <strong>maßgeschneiderte Kliniklösung</strong> und begleiten Sie aktiv von
    Beginn an während der gesamten Umsetzungsphase der Projektergebnisse, ohne
    dass Ihnen hierdurch Mehrkosten entstehen.
  </p>

  <p>
    Wir führen ein stringentes Projektmanagement ein, damit die Projekte in
  </p>
  <ul>
    <li>
      Time
    </li>
    <li>
      Budget
    </li>
    <li>
      Qualität
    </li>
  </ul>
  <p>
    bei Ihnen <strong>erfolgreich</strong> und
    <strong>amortisierend</strong> umgesetzt werden.
  </p>

  <p>
    Mit <strong>Management-Coaching</strong> bieten wir Ihnen Training "on the
    job" für kostenverantwortliche
  </p>

  <ul>
    <li>
      Klinikmitarbeiter
    </li>
    <li>
      Klinikführungskräfte
    </li>
    <li>
      Klinikgeschäftsführer
    </li>
    <li>
      Klinikvorstände
    </li>
  </ul>
  <p>
    und bei Bedarf weiterführende Schulungen.
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {},
})
</script>
